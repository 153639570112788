import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['address', 'city', 'state', 'country', 'zip', 'countryCode', 'stateCode']

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget,
      {
        fields: ['address_components'],
        types: ['address']
      }
    )

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.clearFields()
      place.address_components.forEach(component => { this.fillFields(component) })
    })
  }

  clearFields() {
    if (this.hasCityTarget) { this.cityTarget.value = '' }
    if (this.hasStateTarget) { this.stateTarget.value = '' }
    if (this.hasCountryTarget) { this.countryTarget.value = '' }
    if (this.hasZipTarget) { this.zipTarget.value = '' }
    if (this.stateCodeTarget) { this.stateCodeTarget.value = '' }
    if (this.countryCodeTarget) { this.countryCodeTarget.value = '' }
  }

  onChangeAddress() {
    this.clearFields();
  }

  fillFields(component) {
    switch (true) {
      case this.isCity(component.types):
        if (this.hasCityTarget) { this.cityTarget.value = component.long_name }
        break
      case this.isState(component.types):
        if (this.hasStateTarget) {
          this.stateTarget.value = component.long_name;
          this.stateCodeTarget.value = component.short_name;
        }
        break
      case this.isCountry(component.types):
        if (this.hasCountryTarget) {
          this.countryTarget.value = component.long_name;
          this.countryCodeTarget.value = component.short_name;
        }
        break
      case this.isZip(component.types):
        if (this.hasZipTarget) { this.zipTarget.value = component.long_name }
        break
    }

    if (this.hasZipTarget && this.zipTarget.value === '') {
      this.zipTarget.value = 'N/A'
    }
  }

  isCity(component_types) {
    return component_types.includes('locality') || component_types.includes('sublocality_level_1') || component_types.includes('sublocality')
  }

  isState(component_types) {
    return component_types.includes('administrative_area_level_1')
  }

  isCountry(component_types) {
    return component_types.includes('country')
  }

  isZip(component_types) {
    return component_types.includes('postal_code')
  }
}